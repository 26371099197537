import { Box, type BoxProps, Center, Flex, SimpleGrid, type SimpleGridProps } from '@chakra-ui/react'
import React from 'react'

type ColorProp = BoxProps['bg']
export const columnColors: ColorProp[] = [
  'orange.200',
  'pink.100',
  'red.200',
  'green.100',
  'blue.100',
  'gray.100',
  'purple.100',
  'yellow.100',
  'orange.200',
]

// const pictograms = [
//   {
//     label: 'I',
//     category: ''
//   }
// ]

export const Pictogram: React.FC<{ label: string; color: ColorProp }> = ({ label, color }) => {
  return (
    <Box borderWidth="1px" rounded="md" bg={color} _hover={{ bg: 'gray.100' }} cursor="pointer">
      <Center h="100%">{label}</Center>
    </Box>
  )
}

export const PictogramGrid: React.FC<{ numCols: number; numRows: number } & SimpleGridProps> = ({
  numCols,
  numRows,
  ...props
}) => {
  return (
    <Flex flex={1} direction="column">
      <SimpleGrid h="80px" templateColumns={`repeat(${numCols}, 1fr)`} spacingX={2} px={4}>
        {[...Array(numCols).keys()].map((key: number) => (
          <Box h="60px" bg={columnColors[key % numCols]} roundedTop="xl" p={3} _hover={{ h: '40px' }} alignSelf="end">
            {key}
          </Box>
        ))}
      </SimpleGrid>

      <SimpleGrid {...props} flex={1} templateColumns={`repeat(${numCols}, 1fr)`}>
        {[...Array(numRows * numCols).keys()].map((key: number) => (
          <Pictogram label={`${key}`} color={columnColors[key % numCols]} />
        ))}
      </SimpleGrid>
    </Flex>
  )
}

export const AacPage: React.FC = () => {
  return (
    <Flex w="100vw" h="100vh" direction="column" overflow="hidden" as="main">
      <Flex as="header" direction="row" h="60px">
        <Box flex={1} bg="green.100" />
      </Flex>
      <PictogramGrid flex={1} borderTopWidth="2px" spacing={2} px={4} pt={1} pb={4} numCols={9} numRows={6} />
    </Flex>
  )
}
