import * as React from 'react'
import { ChakraProvider, theme } from '@chakra-ui/react'
import { BrowserRouter as Router } from 'react-router-dom'

import { Routes } from 'routes'

export const App = (): React.ReactElement => (
  <ChakraProvider theme={theme}>
    <Router>
      <Routes />
    </Router>
  </ChakraProvider>
)
