import { Container, Heading, Link } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

import React from 'react'

export const About: React.FC = () => {
  return (
    <Container py={20}>
      <Heading>About Page</Heading>
      <RouterLink to="/">
        <Link color="teal.500" fontSize="2xl" target="_blank" rel="noopener noreferrer">
          Go Home
        </Link>
      </RouterLink>
    </Container>
  )
}
