import { Grid, VStack, Button, Code, Text, Box, Link } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { ColorModeSwitcher } from 'ColorModeSwitcher'
import { Logo } from 'Logo'
import React from 'react'

export const Home: React.FC = () => {
  return (
    <Box textAlign="center" fontSize="xl">
      <Grid minH="100vh" p={3}>
        <ColorModeSwitcher justifySelf="flex-end" />
        <VStack spacing={8}>
          <Logo h="40vmin" pointerEvents="none" />
          <Button>Test</Button>
          <Text>
            Edit <Code fontSize="xl">src/App.tsx</Code> and save to reload.
          </Text>
          <RouterLink to="/about">
            <Link color="teal.500" fontSize="2xl" target="_blank" rel="noopener noreferrer">
              About
            </Link>
          </RouterLink>
        </VStack>
      </Grid>
    </Box>
  )
}
