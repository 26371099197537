import { Route, Routes as MainRoutes } from 'react-router-dom'

import { Home, About, AacPage } from 'pages'
import React from 'react'

export const Routes = (): JSX.Element => (
  <MainRoutes>
    <Route path="/" element={<Home />} />
    <Route path="/aac" element={<AacPage />} />
    <Route path="/about" element={<About />} />
  </MainRoutes>
)
